"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        1: {
            info: {
                p: "Sign in",
                buttons: { 1: "Sign In as a Patient", 2: "Sign In as a Therapist" },
            },
            step: "Step 1",
        },
        2: {
            info: {
                p: "Perform calibration to adapt algorithm to the face parameters",
                buttons: { 1: "Perform calibration" },
            },
            step: "Step 2",
        },
        3: {
            info: {
                p: "Select exercises for an Individual plan of care",
                buttons: { 1: "Create exercises plan" },
            },
            step: "Step 3",
        },
        4: {
            info: {
                p: "When each exercise is performed for the first time, individual goals will be automatically set, but you can adjust them manually if necessary",
            },
            step: "Step 4",
        },
        5: {
            info: {
                p: "Then, all exercises will be evaluated according to set individual goals",
            },
            step: "Step 5",
        },
        6: {
            info: {
                p: "Track progress and analyze reports to revise the plan of care accordingly",
                buttons: { 1: "Track progress" },
            },
            step: "Step 6",
        },
    },
    ru: {
        1: {
            info: {
                p: "Зарегистрироваться",
                buttons: {
                    1: "Зарегистрироваться как пациент",
                    2: "Зарегистрироваться как логопед",
                },
            },
            step: "Шаг 1",
        },
        2: {
            info: {
                p: "Выполните калибровку, чтобы адаптировать алгоритм к параметрам лица",
                buttons: { 1: "Пройти калибровку" },
            },
            step: "Шаг 2",
        },
        3: {
            info: {
                p: "Выберете упражнения для Индивидуального плана занятий",
                buttons: { 1: "Составить план занятий" },
            },
            step: "Шаг 3",
        },
        4: {
            info: {
                p: "При первой выполнении каждого упражнения будут автоматически подобраны индивидуальные цели, которые при необходимости можно скорректировать",
            },
            step: "Шаг 4",
        },
        5: {
            info: {
                p: "Далее все упражнения будут оцениваться в соответствии с индивидуальными целями",
            },
            step: "Шаг 5",
        },
        6: {
            info: {
                p: "Отслеживайте прогресс и анализируйте отчеты, чтобы соответствующим образом пересмотреть план лечения",
                buttons: { 1: "Отслеживать прогресс" },
            },
            step: "Шаг 6",
        },
    },
};
