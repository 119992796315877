"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        h1: "What EZSpeech covers",
        features: {
            1: { title: "Oral-motor exercises", text: "19 exercises" },
            2: {
                title: "Sounds production and automation",
                text: "More that 200 practices",
            },
            3: { title: "Fine motor exercises", text: "20 exercises" },
            4: { title: "Speech Development", text: "Under development" },
            5: {
                title: "Personified plan of care and goal settings",
                text: "3-5 per each exercise",
            },
            6: { title: "Progress  monitoring" },
        },
    },
    ru: {
        h1: "Что включает EZSpeech",
        features: {
            1: { title: "Артикуляционная гимнастика", text: "19 упражнений" },
            2: {
                title: "Отработка и автоматизация звуков",
                text: "Более 200 практик",
            },
            3: { title: "Упражнения на мелкую моторику", text: "20 упражнений" },
            4: { title: "Развитие речи", text: "В процессе разработки" },
            5: {
                title: "Индивидуальный план занятий и постановка целей",
                text: "3-5 для каждого упражнения",
            },
            6: { title: "Мониторинг результатов" },
        },
    },
};
