"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    en: {
        h1: "The App is free for SLPs and Rehabilitations",
        mainContent: {
            1: {
                p: "Our application is created specifically for speech therapists and speech therapy centers and is available to you absolutely for free! We understand how important it is to have a convenient and effective tool for working with patients, so we offer you all the necessary functions:",
            },
            2: {
                h3: "Subscribe your patients",
                p: "The app is intuitive, allowing you to easily connect your child and monitor their progress at any time.",
                button: "Send me the terms of the referral program",
            },
            3: {
                h3: "Assign exercise program",
                p: "Choose exercises for an Individual Plan of Care based on the needs of your patients.",
            },
            4: {
                h3: "Adjust individual parameters",
                p: "Adjust the exercise parameters to the specific characteristics and limitations of the patient to ensure the effectiveness of sessions.",
            },
            5: {
                h3: "Monitor the process",
                p: "Keep track of your patients' progress by getting detailed reports and analytics on each of them.",
            },
            6: {
                h3: "Receive reports with audio, Video and photo errors",
                p: "Get detailed reports with audio, video and photo screenshots of errors, which will allow you to analyze and adjust exercises more accurately for each patient",
            },
        },
        oralo: {
            h4: "Join our referral program",
            p: " and get additional bonuses by attracting new users! Share our app with colleagues and patients, help them achieve better results, and get rewarded for it.",
        },
        settings: "Make your work easier and more efficient with our app!",
    },
    ru: {
        h1: "Приложение для логопедов и логопедических центов - бесплатно",
        mainContent: {
            1: {
                p: "Наше приложение создано специально для логопедов и логопедических центров и доступно для вас абсолютно бесплатно! Мы понимаем, как важно иметь удобный и эффективный инструмент для работы с пациентами, поэтому предлагаем вам все необходимые функции:",
            },
            2: {
                h3: "Подключайте своих пациентов",
                p: "Присоединяйте своих клиентов и получайте бонусы в рамках нашей реферальной программы.",
                button: "Выслать условия реферальной программы",
            },
            3: {
                h3: "Назначайте программы упражнений",
                p: "Выбирайте упражнения для Индивидуального плана занятий в зависимости от потребностей ваших пациентов.",
            },
            4: {
                h3: "Корректируйте индивидуальные параметры",
                p: "Настраивайте параметры упражнений под конкретные особенности и ограничения пациента, чтобы обеспечить эффективность занятий.",
            },
            5: {
                h3: "Отслеживайте процесс",
                p: "Следите за прогрессом ваших пациентов, получая подробные отчеты и аналитику по каждому из них.",
            },
            6: {
                h3: "Получайте отчеты с аудио, видео- и фотофиксация ошибок",
                p: "Получайте детализированные отчеты с аудио, видео- и фотофиксацией ошибок, что позволит вам точнее анализировать и корректировать упражнения для каждого пациента.",
            },
        },
        oralo: {
            h4: "Присоединяйтесь к нашей реферальной программе",
            p: " и получайте дополнительные бонусы, привлекая новых пользователей! Поделитесь нашим приложением с коллегами и пациентами, помогайте им достигать лучших результатов, и получайте за это вознаграждение.",
        },
        settings: "Сделайте свою работу проще и эффективнее вместе с нашим приложением!",
    },
};
