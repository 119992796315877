"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.t = void 0;
exports.t = {
    en: {
        calibration: "Calibration",
        open_exercise: "Go to the exercise",
        tabs: {
            articulation: "Articulation exercises",
            motor: "Motor exercises",
            sound: "Sounds",
        },
    },
    ru: {
        calibration: "Калибровка",
        open_exercise: "Перейти к упражению",
        tabs: {
            articulation: "Артикуляционная гимнастика",
            motor: "Мелкая моторика",
            sound: "Звуки",
        },
    },
};
